import React from "react";
import Footer from "components/Footer";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import constants from "site-constants";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const siteUrl = constants.siteUrl;

export default function Privacy() {
  const { t } = useTranslation("common", "privacy");

  return (
    <>
      <Helmet>
        <title>{t(`pageTitle`)}</title>
        <meta name="title" content={t(`pageTitle`)} />
        <meta name="description" content={t(`pageDescription`)} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={t(`pageTitle`)} />
        <meta property="og:description" content={t(`pageDescription`)} />
        <meta property="og:image" content="/images/share.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={siteUrl} />
        <meta property="twitter:title" content={t(`pageTitle`)} />
        <meta property="twitter:description" content={t(`pageDescription`)} />
        <meta property="twitter:image" content="/images/share.png" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-1Y8LCEBD3R"
        ></script>
        <script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-1Y8LCEBD3R');
`}</script>
      </Helmet>

      <div className="px-4 py-16 mx-auto text-gray-300 max-w-7xl sm:px-6 lg:py-20 lg:px-8">
        <div className="max-w-[200px] mb-6">
          <Link to="/" lang="sr-Cyrl">
            <img src="/images/symbol.svg" alt="" width="563px" height="241px" />
          </Link>
        </div>
        <div className="support-page">
          <h1>{t(`privacy:title`)}</h1>
          <h2>{t(`privacy:subtitle1`)}</h2>
          <p>{t(`privacy:p1`)}</p>
          <p>{t(`privacy:p2`)}</p>
          <p>{t(`privacy:p3`)}</p>
          <ul>
            <li>{t(`privacy:li1`)}</li>
            <li>{t(`privacy:li2`)}</li>
            <li>{t(`privacy:li3`)}</li>
            <li>{t(`privacy:li4`)}</li>
          </ul>
          <p>{t(`privacy:p4`)}</p>
          <ul>
            <li>{t(`privacy:li5`)}</li>
            <li>{t(`privacy:li6`)}</li>
            <li>{t(`privacy:li7`)}</li>
          </ul>
          <p>{t(`privacy:p5`)}</p>
          <p>{t(`privacy:p6`)}</p>
          <p>{t(`privacy:p7`)}</p>
          <p>{t(`privacy:p8`)}</p>
          <h2>{t(`privacy:subtitle2`)}</h2>
          <p>{t(`privacy:p9`)}</p>
          <p>{t(`privacy:p10`)}</p>
          <h2>{t(`privacy:subtitle3`)}</h2>
          <p>{t(`privacy:p11`)}</p>
          <h2>{t(`privacy:subtitle4`)}</h2>
          <p>{t(`privacy:p12`)}</p>
          <h2>{t(`privacy:subtitle5`)}</h2>
          <p>{t(`privacy:p13`)}</p>
          <h2>{t(`privacy:subtitle6`)}</h2>
          <p>{t(`privacy:p14`)}</p>
          <h2>{t(`privacy:subtitle7`)}</h2>
          <p>{t(`privacy:p15`)}</p>
          <h2>{t(`privacy:subtitle8`)}</h2>
          <p>{t(`privacy:p16`)}</p>
          <h2>{t(`privacy:subtitle9`)}</h2>
          <p>{t(`privacy:p17`)}</p>
          <h2>{t(`privacy:subtitle10`)}</h2>
          <p>{t(`privacy:p18`)}</p>
          <h2>{t(`privacy:subtitle11`)}</h2>
          <p>{t(`privacy:p19`)}</p>
          <h2>{t(`privacy:subtitle12`)}</h2>
          <p>{t(`privacy:p20`)}</p>
          <h2>{t(`privacy:subtitle13`)}</h2>
          <p>{t(`privacy:p21`)}</p>
          <p>{t(`privacy:p22`)}</p>
          <h2>{t(`privacy:subtitle14`)}</h2>
          <p>{t(`privacy:p23`)}</p>
          <h2>{t(`privacy:subtitle15`)}</h2>
          <p>
            <Trans key="privacy:p24">
              РНИДС задржава право да измени ову Политику приватности. РНИДС ће
              све промене ове Политике приватности објавити на свом сајту на
              адреси{" "}
              <a
                href="https://rnids.rs"
                target="_blank"
                rel="noopener noreferrer"
              >
                rnids.rs
              </a>{" "}
              /{" "}
              <a
                href="https://рнидс.срб"
                target="_blank"
                rel="noopener noreferrer"
              >
                рнидс.срб
              </a>
              .
            </Trans>
          </p>
          <h2>{t(`privacy:subtitle16`)}</h2>
          <p>
            <Trans key="privacy:p25">
              Да бисте изменили своје податке, исправили их или избрисали, или
              ако имате нека друга питања у вези са овом Политиком приватности,
              можете нас контактирати путем адресе е-поште:{" "}
              <a
                href="mailto:privacy@rnids.rs"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy@rnids.rs
              </a>
            </Trans>
          </p>

          <ul className="list-disc">
            <li>{t(`privacy:li1`)}</li>
            <li>{t(`privacy:li2`)}</li>
            <li>{t(`privacy:li3`)}</li>
          </ul>

          <p>{t(`privacy:p6`)}</p>
        </div>

        <div className="mt-12">
          <Footer />
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "privacy"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
